import React, {useEffect, useState, useGlobal} from "reactn";
import {useParams} from 'react-router-dom'
import moment from 'moment';
import {Row, Col, Form, Input, Button, Switch, DatePicker, Card, message, Select, InputNumber} from 'antd';
import {useHistory} from 'react-router-dom';
import {LeftOutlined} from "@ant-design/icons";
import {CityPicker, FileInputSingle, ModulesPicker} from '../components';
import api from '../service/index'
import {Link} from 'react-router-dom';
import {useCallback} from "react";
import {env} from '../app.config'
import 'react-phone-input-2/lib/style.css'

const {Option} = Select;

const FeedDetail = (props) => {
    let params = useParams()
    let history = useHistory()
    let id = params.id !== "add" ? params.id : false;
    let [languagesCms] = useGlobal("languagesCms"); 

    let newRecord = {
        order: 0,
        active: true,
    }
    let loginType = env.LOGINTYPE
    let [data, setData] = useState(id ? [] : newRecord);
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(id ? true : false);
    let [validationCheck, setValidationCheck] = useState(false)
    let [isSmall,] = useGlobal('isSmall')

    let [modules] = useGlobal("modules");
    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

     
    useEffect(() => {
        if (modules && id) {
            setLoading(true)
            api.get("/rest/feeds/" + id).then(({data: {result, result_message}}) => {
                if (id) {
                    result.sendSms = false
                }
                delete result.password;
                setData(result);
                setLoading(false)
            });
        }
    }, [id]);

    let validate = useCallback(() => {
        let errors = {};

        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [data]);

    useEffect(() => {
        if (validationCheck) setErrors(validate())
    }, [validationCheck, data, validate]);


    let save = async () => {
        setValidationCheck(true)
        let err = validate()
        if (err.hasError) {
            setErrors(err)
            window.scrollTo({top: 20, behavior: 'smooth'});
        } else {

            if (id) {
                api.put("/rest/feeds/" + id, data).then(({data: {result, result_message}}) => {
                    if (result_message.type === 'success') {
                        message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
                        history.push('/feeds')
                    } else
                        message.error(result_message.message, 2);
                })
            } else {

                api.post("/rest/feeds", data).then(({data: {result, result_message}}) => {
                    if (result_message.type === 'success') {
                        message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
                        history.push('/feeds')
                    } else {
                        message.error(result_message.message, 2);
                    }
                })
            }
        }
    };
    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                    <Link to="/feeds">
                        <Button type="light" icon={<LeftOutlined/>} size={'large'}>{!isSmall && languagesCms.BACK}</Button>
                    </Link>
                </div>
            </div>
            <div className="form-wrap">
                <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
                    <Form layout="horizontal" size={"large"} onFinish={save}>

                        <Row direction="row">
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={languagesCms.NAME} required help={errors.name}
                                            validateStatus={errors.name ? 'error' : 'success'}>
                                        <Input name="name" value={data.name}
                                            onChange={e => setData({...data, name: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                                          <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={languagesCms.STATUS} required help={errors.active} validateStatus={errors.active ? 'error' : 'success'}>
                                         <Switch checked={data.active ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, active: v })} />
                                    </Form.Item>
                            </Col>
                        </Row>

                        <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.ORDER} help={errors.order} validateStatus={errors.order ? 'error' : 'success'}>
                  <InputNumber value={data.order} min={0} defaultValue={0} style={{ width: 100 }} onChange={v => setData({ ...data, order: v })} />
                </Form.Item>
              </Col>
            </Row>
                        <Row direction="row">
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" disabled={loading} htmlType="submit" size="large"
                                            block> {languagesCms.SAVE} </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div>
        </div>
    );
};
export default FeedDetail;
