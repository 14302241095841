import React, { useEffect, useState, useGlobal } from 'reactn';
import { Row, Col, Form, Input, Button, Card, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { SettingOutlined } from '@ant-design/icons';
import api from '../../service/index';
import { Link } from 'react-router-dom';

const SmsAndEmailMessage = (props) => {
  let history = useHistory();
  let id = 'smsAndEmailMessage';
  let [data, setData] = useState({});
  let [errors] = useState([]);
  let [loading, setLoading] = useState(true);
  let [languagesCms] = useGlobal('languagesCms');

  useEffect(() => {
    get();
  }, []);

  let get = async () => {
    if (id) {
      await api
        .get('/rest/settings/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  let save = async () => {
    if (id) {
      api
        .put('/rest/settings/' + id, data)
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/settings');
          } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        });
    } else {
      api
        .post('/rest/settings', data)
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/settings');
          } else {
            message.error(result_message.message, 2);
          }
        });
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Sms And Email Message</h1>
        </div>
        <div className="list-buttons">
          <Link to="/settings">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading && (
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}>
            <Form layout="horizontal" size={'large'} onFinish={save}>
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    label="id"
                    help={errors._id}
                    validateStatus={errors._id ? 'error' : 'success'}
                  >
                    <Input
                      name="_id"
                      disabled={true}
                      value={data._id}
                      onChange={(e) =>
                        setData({ ...data, _id: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Card title={'messages'}>
                <div className="table-wrap" style={{ marginTop: 10 }}>
                  <ul className="dragDrop">
                    {data.messages.map((item, index) => {
                      return (
                        <li>
                          <div>
                            <SettingOutlined style={{ fontSize: 20 }} />
                            <span className="nameDrag">{item.lang}</span>
                          </div>

                          <div className="rightButtons">
                            <Link
                              to={
                                '/settings/smsAndEmailMessage/messages/' +
                                id +
                                '/edit/' +
                                index
                              }
                            >
                              <Button type="primary" size="medium">
                                {' '}
                                {languagesCms.EDIT}
                              </Button>
                            </Link>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Card>

              <Card title={'mailMessages'}>
                <div className="table-wrap" style={{ marginTop: 10 }}>
                  <ul className="dragDrop">
                    {data.mailMessages.map((item, index) => {
                      return (
                        <li>
                          <div>
                            <SettingOutlined style={{ fontSize: 20 }} />
                            <span className="nameDrag">{item.lang}</span>
                          </div>

                          <div className="rightButtons">
                            <Link
                              to={
                                '/settings/smsAndEmailMessage/mailMessages/' +
                                id +
                                '/edit/' +
                                index
                              }
                            >
                              <Button type="primary" size="medium">
                                {' '}
                                {languagesCms.EDIT}
                              </Button>
                            </Link>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Card>

              <Card title={'cmsMessages'}>
                <div className="table-wrap" style={{ marginTop: 10 }}>
                  <ul className="dragDrop">
                    {data.cmsMessages.map((item, index) => {
                      return (
                        <li>
                          <div>
                            <SettingOutlined style={{ fontSize: 20 }} />
                            <span className="nameDrag">{item.lang}</span>
                          </div>

                          <div className="rightButtons">
                            <Link
                              to={
                                '/settings/smsAndEmailMessage/cmsMessages/' +
                                id +
                                '/edit/' +
                                index
                              }
                            >
                              <Button type="primary" size="medium">
                                {' '}
                                {languagesCms.EDIT}
                              </Button>
                            </Link>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Card>

              <Row direction="row">
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" block>
                      {' '}
                      {languagesCms.SAVE}{' '}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        )}
      </div>
    </div>
  );
};

export default SmsAndEmailMessage;
